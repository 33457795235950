<template>
   <v-responsive>
        <v-container>

            <v-sheet width="300" class="mx-auto" elevation="1">
                <v-label>Enter password</v-label>

                <v-form @submit.prevent>
                    <v-text-field label="User" append-inner-icon="mdi-account" type="text"
                        v-model="uname"></v-text-field>
                    <v-text-field label="Password" append-inner-icon="mdi-lock" type="password"
                        v-model="passwd"></v-text-field>
                    <v-btn prepend-icon="mdi-login" class="mb-3" elevation="4" @click="doLogin"
                        :disabled="loginDisabled">Login</v-btn>
                </v-form>

            </v-sheet>

        </v-container>
    </v-responsive>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import session from '@/session.js'

export default {
    data() {
        return{
            uname:"",
            passwd:"",
            loginDisabled: false
        }
    },

    methods: {
        processSuccess(data){
            console.log(data)
            if(data.StatusId==0){
                console.log("todo bien")
                session.ssd('meta-data', JSON.stringify(data))
                session.ssd('uname', this.uname)
                session.ssd('passwd', this.passwd)
                store.commit('loadMetadata')
                this.$router.replace({name:'home'})              
            }
        },

        doLogin() {
           axios.post('/api/get-metadata',{},{
                auth:{
                    username: this.uname,
                    password: this.passwd
                }
            })
            .then(resp => {this.processSuccess(resp.data)})
            .catch(error => {console.log("err cons log ap"+error)})
    
        }
    },
    mounted(){
    }
}
</script>