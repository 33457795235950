<template>
    <div>Plese wait...</div>
</template>
<script>
import session from '@/session.js'
export default {
    mounted() {
        this.checkData()
    },

    methods:{
        checkData() {
            var uname = session.gsd("uname")
            var passwd = session.gsd("passwd")

            if(uname!=null && passwd!=null){
                this.$router.replace({name: 'home'})
            }else{
                this.$router.replace({name: 'login'})
            }
        }
    }
}
</script>