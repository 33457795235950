<template>
    <v-responsive>
        <v-container>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="alertsList">

                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-responsive>
</template>

<script>
import store from '@/store';
    export default {
        data(){
            return {
                headers:[
                    {value:"Name", title:"G.Name"},
                    {value:"Alert", title:"Alert"}
                ]
            }
        },

        computed: {
            alertsList(){
                 return store.state.alerts
            }
        }
    }
</script>