<template>
    <v-responsive>
        <v-container>
            <v-row>
                <v-col>
                    <v-btn @click="readFromClipboard" color="blue-grey-darken-3"><v-icon>mdi-content-paste</v-icon>
                        Paste
                        SMS</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col><v-form @submit.prevent>

                        <v-text-field v-if="expenseDateTime" label="Time" v-model="formatedTimestamp" :readonly="true"
                            prepend-inner-icon="mdi-clock-outline" />

                        <v-select :items="groupsItems" v-model="selectedGroupId" item-value="ID" item-title="Name"
                            label="Expense group" prepend-inner-icon="mdi-group" />
                        <span v-if="selectedGroup != null" v-text="selectedGroupSummaryText"
                            class="text-amber mb-4"></span>
                        <v-text-field v-model="amount" label="Amount" type="number"
                            prepend-inner-icon="mdi-currency-usd" pattern="[0-9]*" />

                        <v-text-field v-model="description" label="Description" prepend-inner-icon="mdi-pencil" />

                        <v-select v-model="paymentMethodId" :items="paymentMethodsItems" item-value="ID"
                            item-title="Name" label="Payment Method" prepend-inner-icon="mdi-credit-card" />

                        <v-switch v-model="newSupplier" label="Add supplier"></v-switch>

                        <v-text-field v-if="newSupplier" v-model="supplierName" label="New supplier name"
                            prepend-inner-icon="mdi-domain"></v-text-field>
                        <v-select v-else v-model="supplierName" :items="suppliersItems" item-value="Name"
                            item-title="Name" label="Supplier" prepend-inner-icon="mdi-domain" />


                    </v-form></v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn prepend-icon="mdi-download-box" @click="saveNewExpense" :disabled="!saveEnabled"
                        color="teal-darken-3">Save this
                        expense</v-btn>
                </v-col>
            </v-row>
        </v-container>


    </v-responsive>
</template>

<script>
import store from '@/store';
import axios from 'axios';
import session from '@/session.js'
import { format, parse } from 'date-fns'

export default {
    data() {
        return {
            selectedGroupId: "",
            selectedGroup: null,
            amount: "",
            paymentMethodId: "",
            supplierName: "",
            description: "",
            newSupplier: false,
            expenseTimestamp: 0,
            expenseDateTime: null
        }
    },
    watch: {
        selectedGroupId: function (val) {
            let allGroups = store.getters.GroupsStatus
            for (var i = 0; i < allGroups.length; i++) {
                if (allGroups[i].ID == val) {
                    this.selectedGroup = allGroups[i]
                    return
                }
            }
            this.selectedGroup = null
        }
    },
    methods: {
        metricFormat(numero) {
            if (numero < 1000) {
                return numero.toString();
            } else if (numero >= 1000 && numero < 1000000) {
                const resultadoK = (numero / 1000).toFixed(1);
                return resultadoK.endsWith('.0') ? resultadoK.slice(0, -2) + 'k' : resultadoK + 'k';
            } else if (numero >= 1000000 && numero < 1000000000) {
                const resultadoM = (numero / 1000000).toFixed(1);
                return resultadoM.endsWith('.0') ? resultadoM.slice(0, -2) + 'm' : resultadoM + 'm';
            } else {
                const resultadoB = (numero / 1000000000).toFixed(1);
                return resultadoB.endsWith('.0') ? resultadoB.slice(0, -2) + 'b' : resultadoB + 'b';
            }
        },

        saveNewExpense() {
            //console.log("guardando")
            let body = {
                GroupID: this.selectedGroupId,
                Amount: parseInt(this.amount),
                PaymentMethod: this.paymentMethodId,
                SupplierName: this.supplierName.trim(),
                Description: this.description.trim(),
                Timestamp: this.expenseTimestamp
            }
            //console.log(body)
            //console.log(session.gsd("uname"))
            //console.log(session.gsd("passwd"))


            axios.post(
                "/api/add-expense",
                body,
                {
                    auth: {
                        username: session.gsd("uname"),
                        password: session.gsd("passwd")
                    }
                }
            ).then(resp => {
                session.ssd('meta-data', JSON.stringify(resp.data))
                store.commit('loadMetadata')
                this.clearFields()
            })
                .catch(error => { console.log("err cons log ap" + error) })
        },
        clearFields() {
            this.selectedGroupId = ""
            this.amount = ""
            this.paymentMethodId = ""
            this.supplierName = ""
            this.description = ""
            this.newSupplier = false
            this.expenseTimestamp = 0
            this.expenseDateTime = null
        },

        readFromClipboard() {
            if (document.hasFocus()) {
                //console.log("reading clipboard")
                navigator.clipboard.readText().then(text => {
                    //console.log("all: " + text)
                    if (text.includes("Bancolombia le informa") || text.includes("Realizaste una transferencia con QR") || text.includes("Bancolombia le informa Retiro por") || text.includes("Bancolombia te informa Pago por") || text.includes("Bancolombia: Transferiste $") || text.includes("Bancolombia: Pagaste $") || text.includes("Bancolombia le informa Transferencia por ")) {

                        var isTransfer = text.includes("Bancolombia le informa Transferencia por ")
                        console.log("transfer: " + isTransfer)

                        //looking amount
                        let amountRegex = /(COP)|\$\d+\.*,*\d+(,|\.)*\d{0,2}/

                        var amount = text.substring(text.search(amountRegex), text.length)
                        console.log("amo: ", amount)
                        if (isTransfer) {
                            amount = amount.substring(0, amount.indexOf(" desde "))
                        }else{
                            var amountDecIdx
                            if ((amountDecIdx = amount.search(/(,|\.)\d{2}\D/)) > 0) {//tiene decimales?
                                amount = amount.substring(0, amountDecIdx)
                            } else {
                                amount = amount.substring(0, amount.search(/(,\s|.\s|\s)/))
                            }
                        }

                        amount = amount.replaceAll(",", "").replaceAll(".", "").replaceAll("COP", "").replaceAll("$", "")

                        //console.log("cbAmount " + amount)
                        this.amount = amount


                        //looking for provider
                        var supplier
                        if (text.toLowerCase().includes("bancolombia le informa compra por")) {

                            supplier = text.substring(text.indexOf(","), text.length)
                            supplier = supplier.substring(6, supplier.indexOf(":") - 2).trim()
                            //console.log("cbSupplier " + supplier)

                        } else if (text.toLowerCase().includes("bancolombia te informa pago por $")) {
                            var endOfSupplier = text.search(/desde\sproducto\s\*\d{4}\.\s/)
                            supplier = text.substring(text.indexOf("Pago por $") + 10, endOfSupplier)
                            supplier = supplier.substring(supplier.indexOf("a ") + 2, supplier.length).trim()
                            //console.log("supplier2: "+supplier)
                        } else if (text.toLowerCase().includes("por boton bancolombia a ")) {
                            supplier = text.substring(text.indexOf("por Boton Bancolombia a ") + 24, text.indexOf(" desde producto *")).trim()
                            //console.log("supplier3:"+supplier)
                        } else if (text.toLowerCase().includes("bancolombia: pagaste $")) {
                            supplier = text.substring(20, text.indexOf(" desde tu producto *"))
                            supplier = supplier.substring(supplier.indexOf(" a ") + 3, supplier.length)
                            //console.log("supplier4:"+supplier)
                        }


                        //supplier already exists?
                        let alreadySuppliers = store.getters.Suppliers
                        this.newSupplier = true
                        for (var i = 0; i < alreadySuppliers.length; i++) {
                            let alreadySupplier = alreadySuppliers[i]
                            if (alreadySupplier.Name == supplier) {
                                this.newSupplier = false
                                break
                            }
                        }
                        this.supplierName = supplier




                        //looking payment method
                        var cbMethod
                        var pmIdx

                        if ((pmIdx = text.indexOf("T.Cred *")) > 0) {
                            cbMethod = text.substring(pmIdx + 8, pmIdx + 12).trim()


                        } else if ((pmIdx = text.indexOf("T.Deb *")) > 0) {
                            cbMethod = text.substring(pmIdx + 7, pmIdx + 11).trim()


                        } else if ((pmIdx = text.indexOf("desde cta *")) > 0) {
                            //console.log("idx: " + pmIdx)
                            cbMethod = text.substring(pmIdx + 11, pmIdx + 15).trim()

                        } else if ((pmIdx = text.indexOf("desde producto *")) > 0) {
                            //console.log("idx: " + pmIdx)
                            cbMethod = text.substring(pmIdx + 16, pmIdx + 20).trim()

                        } else if ((pmIdx = text.indexOf("desde tu producto *")) > 0) {
                            //console.log("idx: " + pmIdx)
                            cbMethod = text.substring(pmIdx + 19, pmIdx + 23).trim()

                        }

                        //
                        //console.log("cbMethod: ", cbMethod)

                        let methods = store.getters.PaymentMethods
                        for (i = 0; i < methods.length; i++) {
                            let method = methods[i]
                            if (method.ID.includes(cbMethod)) {
                                this.paymentMethodId = method.ID
                                break
                            }
                        }

                        //looking for timestamp
                        let timestampRegex1 = /\d\d:\d\d\.\s\d\d\/\d\d\/\d\d\d\d/;//05:56. 17/01/2024
                        let timestampRegex2 = /\d\d\/\d\d\/\d\d\d\d\s\d\d:\d\d\./;//03/01/2024 11:41.
                        let timestampRegex3 = /\d{4}\/\d{2}\/\d{2}\s\d{2}:\d{2}\./;//2024/01/16 08:27.
                        let timestampRegex4 = /\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}:\d{2}\s/;//03/01/2024 15:35:28 

                        var tstampIndex
                        var tstampStr
                        var tstampDate

                        if ((tstampIndex = text.search(timestampRegex1)) > 0) {//05:56. 17/01/2024
                            tstampStr = text.substring(tstampIndex, tstampIndex + 17).trim()
                            //console.log("1firsr step: " + tstampStr)
                            tstampDate = parse(tstampStr, "HH:mm. dd/MM/yyyy", new Date())
                            //console.log(tstampDate)
                            this.expenseDateTime = tstampDate
                            this.expenseTimestamp = tstampDate.getTime()

                        } else if ((tstampIndex = text.search(timestampRegex2)) > 0) {// 03/01/2024 11:41.
                            tstampStr = text.substring(tstampIndex, tstampIndex + 17).trim()
                            //console.log("2firsr step: " + tstampStr)
                            tstampDate = parse(tstampStr, "dd/MM/yyyy HH:mm.", new Date())
                            //console.log(tstampDate)
                            this.expenseDateTime = tstampDate
                            this.expenseTimestamp = tstampDate.getTime()
                        } else if ((tstampIndex = text.search(timestampRegex3)) > 0) {// 2024/01/16 08:27.
                            tstampStr = text.substring(tstampIndex, tstampIndex + 17).trim()
                            //console.log("3firsr step: " + tstampStr)
                            tstampDate = parse(tstampStr, "yyyy/MM/dd HH:mm.", new Date())
                            //console.log(tstampDate)
                            this.expenseDateTime = tstampDate
                            this.expenseTimestamp = tstampDate.getTime()
                        } else if ((tstampIndex = text.search(timestampRegex4)) > 0) {// 03/01/2024 15:35:28 
                            tstampStr = text.substring(tstampIndex, tstampIndex + 19).trim()
                            //console.log("2firsr step: " + tstampStr)
                            tstampDate = parse(tstampStr, "dd/MM/yyyy HH:mm:ss", new Date())
                            //console.log(tstampDate)
                            this.expenseDateTime = tstampDate
                            this.expenseTimestamp = tstampDate.getTime()
                        }


                        //console.log("date: ", tstampDate)
                        if (tstampDate) {
                            this.expenseDateTime = tstampDate
                            this.expenseTimestamp = tstampDate.getTime()
                        }

                    }
                })
            }
        }
    },

    computed: {
        groupsItems() {
            return store.getters.GroupsStatus
        },
        paymentMethodsItems() {
            return store.getters.PaymentMethods
        },
        suppliersItems() {
            return store.getters.Suppliers
        },
        selectedGroupSummaryText() {

            if (this.selectedGroup != null) {
                return "Group spent $" + this.metricFormat(this.selectedGroup.CopExpent) + " of $" + this.metricFormat(this.selectedGroup.CopBudget) + " (" + this.selectedGroup.ConsumedPercent + "%)"

            } else return null


        },
        saveEnabled() {
            let nonEmpty = this.selectedGroupId != "" && this.amount != "" && this.paymentMethodId != "" && this.supplierName != "" && this.description != ""
            let valid = this.amount > 0
            return nonEmpty && valid
        },
        formatedTimestamp() {
            if (this.expenseDateTime != null) {
                return format(this.expenseDateTime, "dd/MMM/yyyy HH:mm")
            } else {
                return null
            }
        }
    },

    mounted() {



    }
}
</script>