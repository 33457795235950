<template>
    <v-responsive>
        <v-container>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="dues">
                        <template v-slot:[`item.PaID`]="{ item }">
                            {{ formatMoney(item.PaID) }}
                        </template>
                        <template v-slot:[`item.Amount`]="{ item }">
                            {{ formatMoney(item.Amount) }}
                        </template>
                        <template v-slot:[`item.CurrentDebt`]="{ item }">
                            {{ bankCurrentDebt(item.CurrentDebt, item.CurrentDebtDaysOld) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-row>
                <v-col>Set current bank debt:</v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form>
                        <v-select v-model="setDebt.paymentMethodId" label="Credit Card" :items="creditCardItems" item-value="ID" item-title="Name" prepend-inner-icon="mdi-credit-card"></v-select>
                        <v-text-field v-model="setDebt.amount" label="Due amount" type="number" prepend-inner-icon="mdi-currency-usd"
                            pattern="[0-9]*"></v-text-field>
                        <v-btn color="teal-darken-3" :disabled="!setDebtButtonEnabled" @click="saveCCDebt" prepend-icon="mdi-content-save">Set debt</v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-responsive>
</template>
<script>
import store from '@/store';
import axios from 'axios';
import session from '@/session.js'
export default {
    data() {
        return {
            headers: [
                { value: "ID", title: "CC" },
                { value: "PaID", title: "Paid" },
                { value: "Amount", title: "Left to pay" },
                { value: "CurrentDebt", title: "Bank current debt" }
            ],
            USDollar: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            setDebt:{
                paymentMethodId:"",
                amount:""
            }
        }
    },
    methods: {

        formatMoney(price) {
            return this.USDollar.format(price)

        },
        bankCurrentDebt(amount, days) {
            var out = this.formatMoney(amount)
            if (days > 0) {
                out += "(" + days + " days ago)"
            }
            return out
        },
        saveCCDebt(){
            axios.post("/api/set-cc-debt", null, {
                auth:{
                    username: session.gsd("uname"),
                    password: session.gsd("passwd")
                },
                headers:{
                    pm: this.setDebt.paymentMethodId,
                    amount: this.setDebt.amount
                }
            }).then(resp=>{
                session.ssd('meta-data', JSON.stringify(resp.data))
                store.commit('loadMetadata')
                this.clearFields()
            })
        },
        clearFields(){
            this.setDebt.paymentMethodId=""
            this.setDebt.amount=""
        }

    },

    computed: {
        dues() {
            return store.getters.CCDues
        },
        creditCardItems() {
            return store.getters.CreditCards
        },
        setDebtButtonEnabled(){
            return this.setDebt.paymentMethodId!="" && this.setDebt.amount>1000
        }

    }
}
</script>