<template>
  <SummaryBanner />
  <v-card>
    <v-tabs v-model="tab" bg-color="teal-darken-3">
      <v-tab value="add"><v-icon>mdi-plus</v-icon> Add</v-tab>
      <v-tab value="list"><v-icon>mdi-account-cash</v-icon> Expenses</v-tab>
      <v-tab value="ccdues"><v-icon>mdi-currency-usd-off</v-icon> CC Dues</v-tab>
      <v-tab value="ccpayments"><v-icon>mdi-currency-usd</v-icon> CC Payments</v-tab>
      <v-tab value="alarms"><v-icon>mdi-alert-outline</v-icon>Alarms</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">

        <v-window-item value="add">
          <AddSection />
        </v-window-item>

        <v-window-item value="list">
          <ExpensesSection />
        </v-window-item>

        <v-window-item value="ccdues">
          <DuesSection />
        </v-window-item>


        <v-window-item value="ccpayments">
          <PaymentsSection />
        </v-window-item>

        <v-window-item value="alarms">
          <AlertsSection />
        </v-window-item>

      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store'
import axios from 'axios'
import SummaryBanner from '@/components/SummaryBanner.vue';
import ExpensesSection from '@/components/ExpensesSection.vue';
import AddSection from '@/components/AddSection.vue';
import AlertsSection from '@/components/AlertsSection.vue';
import DuesSection from '@/components/DuesSection.vue';
import PaymentsSection from '@/components/PaymentsSection.vue';
import session from '@/session.js';

export default defineComponent({
  name: 'HomeView',
  components: { SummaryBanner, AddSection, ExpensesSection, AlertsSection, DuesSection, PaymentsSection },
  data() {
    return {
      metaData: {},
      tab: null
    }
  },

  methods: {
    updateMetaData() {
      axios.post('/api/get-metadata', {}, {
        auth: {
          username: session.gsd("uname"),
          password: session.gsd("passwd")
        }
      })
        .then(resp => {
          session.ssd('meta-data', JSON.stringify(resp.data))
          store.commit('loadMetadata')
        })
        .catch(error => { console.log("err cons log ap" + error) })

    }
  },

  mounted() {
    store.commit('loadMetadata')
    if (!store.getters.thereIsMetaData) {
      this.$router.replace({ name: 'login' })
    } else {
      this.updateMetaData()
    }

    let tab = session.gsd("tab")
    if (tab) {
      this.tab = tab
    }

  },
  watch: {
    tab(val) {
      //console.log(val)
      session.ssd("tab", val)
    }
  }

});
</script>
<style>
.v-tabs-bar__content {
  flex-wrap: wrap;
  width: 100%;
}

div.v-tabs-bar {
  height: auto;
}
</style>