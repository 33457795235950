import { createStore } from 'vuex'
import session from '@/session.js'

export default createStore({
  state: {
    metaData: {},
    alerts: []
  },
  getters: {
    thereIsMetaData(state) {
      return state.metaData != null && state.metaData.StatusId == 0
    },
    ExpenseSummary(state) {
      return state.metaData.ExpenseSummary
    },
    GroupsStatus(state) {
      return state.metaData.GroupsStatus
    },
    Expenses(state) {
      if (state.metaData.Expenses)
        return state.metaData.Expenses
      else return []
    },
    PaymentMethods(state) {
      return state.metaData.PaymentMethods
    },
    CreditCards(state) {
      var ccs = []
      //console.log(state.metaData.PaymentMethods)
      for (var i = 0; i< state.metaData.PaymentMethods.length; i++) {
        if (state.metaData.PaymentMethods[i].Credit) {
          ccs.push(state.metaData.PaymentMethods[i])
        }
      }
      return ccs
    },
    Suppliers(state) {
      return state.metaData.Suppliers
    },
    CCDues(state) {
      return state.metaData.CreditCardDues ? state.metaData.CreditCardDues : []
    },
    CCPayments(state) {
      return state.metaData.CreditCardPayments ? state.metaData.CreditCardPayments : []
    },

  },
  mutations: {
    loadMetadata(state) {
      let jsonMd = session.gsd('meta-data')
      if (jsonMd == null) {
        return
      }
      let metaData = JSON.parse(jsonMd)
      if (metaData.StatusId == 0) {
        state.metaData = metaData

      }

      var alerts = []
      for (var i = 0; i < metaData.GroupsStatus.length; i++) {
        let group = metaData.GroupsStatus[i]
        if (group.Alert) {
          alerts.push({
            ID: group.ID,
            Name: group.Name,
            Alert: group.Alert
          })
        }
      }
      state.alerts = alerts


      //console.log('metadata commited')

    }

  },
  actions: {
  },
  modules: {
  }
})
