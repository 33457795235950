<template>
    <v-responsive>
        <v-sheet title="Expense summary">
            <v-container v-if="expenseSummary != null">
                <v-row>
                    <v-col>
                        <span v-text="summaryText"></span>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col>
                        <v-progress-linear striped color="green" height="20"
                            v-model="expenseSummary.ExpentPercentage"></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span v-text="invoicingSummarytext"></span>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-responsive>
</template>
<script>
import store from '@/store'
export default {
    name: "SummaryBanner",

    data() {
        return {}
    },
    methods: {
        metricFormat(numero) {
            if (numero < 1000) {
                return numero.toString();
            } else if (numero >= 1000 && numero < 1000000) {
                const resultadoK = (numero / 1000).toFixed(1);
                return resultadoK.endsWith('.0') ? resultadoK.slice(0, -2) + 'k' : resultadoK + 'k';
            } else if (numero >= 1000000 && numero < 1000000000) {
                const resultadoM = (numero / 1000000).toFixed(1);
                return resultadoM.endsWith('.0') ? resultadoM.slice(0, -2) + 'm' : resultadoM + 'm';
            } else {
                const resultadoB = (numero / 1000000000).toFixed(1);
                return resultadoB.endsWith('.0') ? resultadoB.slice(0, -2) + 'b' : resultadoB + 'b';
            }
        }
    },
    computed: {

        expenseSummary() {
            return store.getters.ExpenseSummary
        },
        summaryText() {
            let data = store.getters.ExpenseSummary
            if (data != null) {
                return "Spent $" + this.metricFormat(data.TotalExpent) + " of $" + this.metricFormat(data.TotalBudget) + " budget (" + data.ExpentPercentage + "%)"

            } else return null
        },
        invoicingSummarytext(){
            let data = store.getters.ExpenseSummary
            if (data != null) {
                return "Extected income w. tax $" + this.metricFormat(data.ExpectedIncome)

            } else return null
        }//
    },

    mounted() {

    }
}
</script>