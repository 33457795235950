<template>
    <v-responsive>
        <v-container>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="payments">
                        <template v-slot:[`item.CopAmount`]="{ item }">
                            {{ formatMoney(item.CopAmount) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form>
                        <v-select v-model="setPayment.billingMonth" :items="billingMonthItems" item-value="value"
                            item-title="title" label="Billing Month" prepend-inner-icon="mdi-calendar-blank"></v-select>

                        <v-select v-model="setPayment.paymentMethodId" :items="creditCardItems" item-value="ID"
                            item-title="Name" label="Credt card" prepend-inner-icon="mdi-credit-card"></v-select>
                        <v-text-field v-model="setPayment.amount" label="Amount paid" type="number"
                            prepend-inner-icon="mdi-currency-usd" pattern="[0-9]*"></v-text-field>

                        <v-text-field v-model="setPayment.notes" label="Notes" type="text"
                            prepend-inner-icon="mdi-text-box"></v-text-field>

                        <v-btn @click="savePayment" prepend-icon="mdi-content-save" color="teal-darken-3" :disabled="!saveEnabled">Add
                            payment</v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-responsive>
</template>
<script>
import store from '@/store';
import axios from 'axios';
import session from '@/session';
import { format } from 'date-fns'

export default {
    data() {
        return {
            headers: [
            { value: "BillingMonth", title: "Month" },
                { value: "CardID", title: "CC" },
                { value: "Amount", title: "Paid" },
                { value: "Notes", title: "Notes" },
                { value: "IssuedBy", title: "Issued by" }
            ],
            USDollar: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            setPayment: {
                billingMonth: "",
                paymentMethodId: "",
                amount: "",
                notes: ""
            }
        }
    },
    methods: {
        formatMoney(price) {
            return this.USDollar.format(price)

        },
        savePayment() {
            axios.post("/api/add-cc-payment", null,
                {
                    auth: {
                        username: session.gsd("uname"),
                        password: session.gsd("passwd")
                    },
                    headers: {
                        bm: this.setPayment.billingMonth,
                        pm: this.setPayment.paymentMethodId,
                        amount: this.setPayment.amount,
                        notes: this.setPayment.notes

                    }
                }
            ).then(resp => {
                if (resp.data.StatusId == 0) {
                    session.ssd('meta-data', JSON.stringify(resp.data))
                    store.commit('loadMetadata')
                    this.clearFields()
                }else{
                    console.log("API Error: " + resp.data.StatusMessage)    
                }
            }).catch(resp => {
                console.log("SysError: " + resp)
            })
        },
        clearFields() {
            this.setPayment.paymentMethodId = ""
            this.setPayment.amount = ""
            this.setPayment.notes=""
        }
    },

    computed: {
        payments() {
            return store.getters.CCPayments
        }, creditCardItems() {
            return store.getters.CreditCards
        },
        billingMonthItems() {
            let bms = []
            let thisMonth = new Date()
            let prevMonth = new Date()

            prevMonth.setMonth(prevMonth.getMonth() - 1)
            prevMonth.setDate(1)

            let d1 = format(thisMonth, "yyyyMM")
            let d1n = format(thisMonth, "MMMM yyyy")

            let d2 = format(prevMonth, "yyyyMM")
            let d2n = format(prevMonth, "MMMM yyyy")

            bms.push({ value: d1, title: d1n })
            bms.push({ value: d2, title: d2n })

            return bms
        },
        saveEnabled(){
            return this.setPayment.paymentMethodId !="" && this.setPayment.amount>5000
        }
    },
    mounted() {
        let thisMonth = new Date()
        let d1 = format(thisMonth, "yyyyMM")
        this.setPayment.billingMonth = d1
    }
}
</script>