import CryptoJS from "crypto-js";

function bsn() {
    let nav = window.navigator;
    let screen = window.screen;
    var a = nav.mimeTypes.length;
    a += nav.userAgent.replace(/\D+/g, "");
    a += nav.plugins.length;
    a += screen.pixelDepth || "";
    return a;
}

let session = {
    gsd: function(id) {
        let enc = localStorage.getItem(CryptoJS.MD5(id));
        if (enc != null) {
            return CryptoJS.AES.decrypt(enc, bsn()).toString(CryptoJS.enc.Utf8);
        } else {
            return null;
        }
    },

    ssd: function(id, data) {
        let id_ = CryptoJS.MD5(id).toString();
        let data_ = CryptoJS.AES.encrypt(data, bsn()).toString();
        localStorage.setItem(id_, data_);
    },

    dsd: function(id) {
        localStorage.removeItem(CryptoJS.MD5(id).toString());
    },

    tok: function() {
        return this.gsd("token");
    },

    lo: function() {
        localStorage.clear();
        window.location = "login.html";
    }
};

export default session

